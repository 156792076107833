.feedback-popup {
    /* background-color: gray; */
    padding-top: 3%;
    width: 50vw;
    height: auto;
    text-align: center;
    align-items: center;
    /* margin-left: 35%;
    margin-right: 35%; */
    color: black;   
    font-family: "Roboto";
    font-size: 1em;
    padding: 1%;
}

.ok-button {
    background-color: black;
    color: white;
    font-size: 20px;
    /* padding: 10px 60px; */
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
  }

