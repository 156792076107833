.chatbot-container {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 700px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: white;
    max-height: 60vh;
    transition: max-height 0.3s ease-in-out;
}

.chatbot-container.open {
    max-height: 80vh;
}

.chatbot-header {
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

.messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    color: #00008B;
    text-align: left;
    display: flex;
    flex-direction: column-reverse;
}

.messages.open {
    display: block;
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    max-width: 70%;
    word-wrap: break-word;
}

.message.user {
    background-color: #DCF8C6; /* Light green for user messages */
}

.message.bot {
    background-color: #F1F0F0; /* Light gray for customer support messages */
}

.message strong {
    font-weight: bold;
    color: #333;
}

.input-container {
    border-top: 1px solid #ddd;
    align-items: center;
    padding: 25px;
    width: 650px;
    display: none;
    margin-left: 25px;
    margin-right: 25px;
}

.input-container.open {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
}

.input {
    width: 550px;
    align-items: center;
    height: 200px;
}
