.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    text-align: center; /* This will center your h1 and p tags */
    width: 100%; /* You can adjust this value if you want the container to have a specific width */
    max-width: 500px; /* You can adjust this value if you want to set a maximum width to your container */
}

.form-control {
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
