.value-sbai {
    background-color: black;
    color: white;
    padding: 3% 3%;
}

.value-title {
    margin-top: 7%;
    font-size: 5.5vh;
}

.value-subheader {
    font-size: 4vh;
    margin-top: 3%;
}

.value-text {
    font-size: 3.5vh;
    margin-top: 3%;
}
