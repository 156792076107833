@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.c-multi-select-dropdown{
    height: 300px;
    overflow: auto;
    margin:5% auto;
    font-family: 'Roboto';
    position:relative;
    width:400px;

    &:hover{
        .c-multi-select-dropdown__options{
            display:block;
        }
    }

    .c-multi-select-dropdown__selected{
        border:solid 1px #eee;
        font-size:14px;
        padding:10px;
        display:flex;
        justify-content: space-between;
        align-items: center;

        > img {
            height:18px;
        }
    }

    .c-multi-select-dropdown__options{
        display:none;
        position: absolute;
        box-sizing: border-box;
        left:0;
        width:100%;
        list-style: none;
        padding-left:0px;
        border:solid 1px #eee;
        padding:5px 0px;
        z-index: 1;
        background-color: #fff; /* Change this to any color you prefer */
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
        border-radius: 4px;
        /*padding: 8px;*/
    }

    /* .c-multi-select-dropdown__option{
        display:flex;
        align-items: center;
        padding:6px 10px;
        cursor:pointer;

        &:hover{
            background-color: #eee;
        }
        
        &-checkbox{
            margin-right:6px;
        }
    } */

    .c-multi-select-dropdown__option {
        display: flex;
        align-items: center;
        /* Add padding between the checkbox and the text */
        padding: 8px 8px;
      }
      
      .c-multi-select-dropdown__option-checkbox {
        /* Add some margin to create space between the checkbox and the text */
        margin-right: 8px;
      }
}

/* .c-single-select-dropdown {
    margin:5% auto;   
    font-family: 'Roboto';
    width:400px;
}

.c-single-select-dropdown__select {
    border:solid 1px #eee;
    font-size:14px;
    padding:10px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto';
} */

/* dropdown.css */

/* ... (styles for the .c-multi-select-dropdown are unchanged) */

.c-single-select-dropdown {
    margin: 5% auto; /* This will center the dropdown horizontally */
    font-family: 'Roboto';
    position: relative;
    width: 400px; /* Set a fixed width, this width should match the one set in your CSS for .c-multi-select-dropdown for consistency */
    display: flex;
    flex-direction: column; /* This will ensure the button container aligns under the select */
    align-items: center; /* This will center the select and button horizontally within the div */
  }
  
  .c-single-select-dropdown__select {
    border: solid 1px #eee;
    font-size: 14px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 400px;
  }

  .c-single-select-dropdown__select_second {
    border: solid 1px #eee;
    font-size: 14px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 400px;
    margin-top: 10%;
  }
  
  .c-single-select-dropdown__options {
    display: none;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    width: 100%;
    list-style: none;
    padding-left: 0px;
    border: solid 1px #eee;
    padding: 5px 0px;
    z-index: 1;
    background-color: #fff; /* Change this to any color you prefer */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    border-radius: 4px;
  }
  
  .c-single-select-dropdown:hover .c-single-select-dropdown__options {
    display: block;
  }
  
  .c-single-select-dropdown__option {
    padding: 8px 8px; /* Same padding as in the multi-select dropdown */
    cursor: pointer;
  }
  
  .c-single-select-dropdown__option:hover {
    background-color: #eee;
  }
  
  .c-single-select-dropdown__option-checkbox {
    /* Add some margin to create space between the checkbox and the text */
    margin-right: 8px; /* Same margin as in the multi-select dropdown */
  }

  .c-single-select-dropdown__input {
    border: solid 1px #eee;
    font-size: 14px;
    padding: 10px;
    margin-top: 10px; /* Add some margin for spacing */
    width: 100%;
    box-sizing: border-box;
    font-family: 'Roboto';
}

.c-single-select-dropdown__state-select {
    border: solid 1px #eee;
    font-size: 14px;
    padding: 10px;
    margin-top: 10px; /* Add some margin for spacing */
    width: 100%;
    box-sizing: border-box;
    font-family: 'Roboto';
}

.submit-button-container {
    margin-top: 20px;
    text-align: center;
    margin-top: 7%;
    /* background-color: #143B5D; */
}

button {
    padding: 10px 20px;
    border: none;
    background-color: #143B5D; /* You can adjust the color */
    color: white;
    border-radius: 5px;
    cursor: pointer;
    /* transition: background-color 0.2s ease; */
}

button:hover {
    background-color: #143B5D; /* Darken on hover, you can adjust the color */
}

button:disabled,
button.inactive {
    background-color: #143B5D; /* You can adjust the color */
    cursor: not-allowed;
}  
