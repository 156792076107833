.productform {
    padding-right: 5%;
    padding-left: 5%;
    padding-top: 5%;
    text-align: center;
}

.recommendation {
    margin-top: 5%;
    margin-bottom: 5%;
}
