.grid {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
}

.cell {
    flex: 1;  /* To ensure each cell takes equal width */
    display: flex;
    align-items: center;  /* To vertically center the button */
    justify-content: center;  /* To horizontally center the button */
    padding: 10px;  /* Add some space around each button */
}

.category-button {
    background-color: #143B5D;
    color: white;
    font-size: 20px;
    /* padding: 10px 60px; */
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}

/* .category-button:active{
    background-color: #43AEE3;    
} */

.button-selected {
    background-color: #BABFD1
}