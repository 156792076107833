.product-table2 {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 10%;
}

tr {
    border: 1px solid black;
}

td, th {
    border: 1px solid black;
    padding: 8px;
}

.rec-title2 {
    color: black;
    font-family: "Roboto";
    margin-bottom: 2%;
    text-align: center;
}

.product-link {
    background-color: #143B5D;
    align-items: center;
    justify-content: center;
    width: 250px;
    display: block;
    margin: auto;
    text-align: center;
}

.product-link:hover {
    background-color: #234A6C;
    align-items: center;
    justify-content: center;
}

.buy-product2 {
    background-color: #143B5D;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}

.product-tbody .product-row:hover {
    background-color: #BABFD1;
}

.product-price {
    color: #143B5D;
    font-weight: bold;
}

.product-store {
    font-family: "Roboto";
    color: black;
}

/* New CSS for category-section */
.category-section2 {
    margin-bottom: 5%;
}
