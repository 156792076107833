.services {
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 5%;
}

.services-title {
    margin-bottom: 5%;
    font-size: 24px;
    color: black;
}

/* .services-dropdown {
    justify-content: center;
    align-items: center;
} */
