.tw {
    @import 'tailwind.css';
  }
  
.custom-divider {
    width: 100%;  /* You can adjust this value as needed */
    height: 2px;  /* This is the thickness of the divider */
    background-color: #E5E7EB;  /* This is a light gray color, you can adjust to fit your design */
    margin: 0.5rem 0;  /* Add some margin above and below the divider */
  }
  

/* @layer components {
    .tw {
      @apply bg-white;
    }
  }
   */