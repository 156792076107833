.chatbot-container2 {
    top: auto; 
    transform: none; 
    position: relative; /* Changed from fixed */
    bottom: auto; /* Added */
    right: auto; /* Added */
    width: 700px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: white;
    max-height: none;
    transition: max-height 0.3s ease-in-out;
    margin: auto; /* Added - centers horizontally */
    top: 50%; /* Added - centers vertically */
    /* transform: translateY(-50%); */
}

/* .chatbot-container2.open {
    max-height: 150vh;
} */

.chatbot-header2 {
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

.messages2 {
    max-height: 40vh;
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    color: #00008B;
    text-align: left;
    display: flex;
    flex-direction: column-reverse;
}

.messages2.open {
    display: block;
}

.message2 {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    max-width: 70%;
    word-wrap: break-word;
}

.message2.user {
    background-color: #DCF8C6; /* Light green for user messages */
}

.message2.bot {
    background-color: #F1F0F0; /* Light gray for customer support messages */
}

.message2 strong {
    font-weight: bold;
    color: #333;
}

.input-container2 {
    border-top: 1px solid #ddd;
    align-items: center;
    padding: 25px;
    width: 650px;
    display: none;
    margin-left: 25px;
    margin-right: 25px;
}

.input-container2.open {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
}

.input2 {
    width: 550px;
    align-items: center;
    height: 200px;
}
