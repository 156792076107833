.legal {
    padding: 5%;
    color: black;
}

.legal h1 {
    margin-bottom: 3%;
    text-align: center;
}

.legal .section-title {
    font-weight: bold;
}

.legal ul {
    list-style-type: disc;
    padding-left: 20px;
}

.legal li {
    margin-bottom: 10px;
}
