.types-of-users {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;
    gap: 5%;
}

.central-product-title {
    margin-bottom: 5%;
    text-align: center;
}

.user-type-button {
    width: 250px;
    background-color: #143B5D;
    color: white;
    font-size: 20px;
    /* padding: 10px 60px; */
    border-radius: 5px;
    /* margin: 5% 5%; */
    margin-left: 5%;
    margin-right: 5%;
    cursor: pointer;
    padding-top: 2%;
    padding-bottom: 2%;
}

.central-product-description {
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 5%;
    text-align: center;
    font-family: "Roboto";
}

.analysis-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;   /* Optional - adjust based on your design needs */
    margin-bottom: 2%; /* Optional - adjust based on your design needs */
}

/* .analysis {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    gap: 5%;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
} */

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;   /* Optional - adjust based on your design needs */
    margin-bottom: 2%; /* Optional - adjust based on your design needs */
}

.product {
    margin-bottom: 10%;
}
