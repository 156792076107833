@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html, body, html * {
  font-family: 'Roboto', sans-serif;
}

.intro {
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
  /* height:400px; */
  width:100%;
  color: white;
  /* background: */ /* linear-gradient(50deg, red, #143B5D); */ /* linear-gradient(135deg, #143B5D, #BABFD1, purple, white); */ /* linear-gradient(to bottom right, white, orange); */
  background-color: #b1bfd8; 
  background-image: linear-gradient(315deg, #b1bfd8 0%, #6782b4 74%); 
  text-align: center;
  font-size: 1.375rem;
  display: flex;
  /* margin-bottom: 5%; */
  /* background:linear-gradient(rgba(250,0,0,0.5),transparent);
  background-color: orange; */
}

.vision {
    margin-top: 5%;
    padding-bottom: 5%;
}

.intro-row {
    /* display: inline-block; */
    flex: 1;
    margin-left: 2%;
    margin-right: 2%;
}

/* .intro-row:first-child {
    margin-right: 10%;
} */