.navbar {
    background-color: #143B5D !important;
    padding-right: 3%;
    padding-left: 1%;
    width: 100%; /* Make the navbar span the full width */
    z-index: 1000; /* Add a high z-index to ensure it appears on top of other content */
}

.nav-link {
    color: white;
    font-family: "Roboto";
}

.navbar-nav.ml-auto {
    margin-left: auto; /* This will right-align the nav items */
}


.navbar-toggler {
    font-size: 7vh;
    color: white;
}

.logo-navbar {
    border-radius: 8%;
}

/* .logo-in-navbar {
    width: 20%;
    height: 20%;
} */

