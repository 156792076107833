/* Default styles for larger screens */
.interior-painting {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 5%;
    justify-content: center;
    margin-bottom: 5%;
}

.image-container {
    padding-left: 15%;
    width: 70%;
    flex: 1;
    position: relative;
    height: 100%;
}

.background-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.text-container {
    width: 40%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: 5%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 3%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-button {
    display: flex;
    justify-content: center;
}

.title-about {
    font-size: 5.5vh; /* Font size for title on smaller screens */
    text-align: center;
}

.description-about {
    font-size: 3.5vh; /* Font size for description on smaller screens */
    margin-top: 2%;
}

.signup-button {
    margin-top: 3%;
    font-size: 3vh;
    padding: 1vw 3vh;
    border-radius: 5%;
    background-color: #BABFD1;
    color: #143B5D;
    text-decoration: none;
    font-weight: bold;
    align-items: center;
}

/* Adjust styles for smaller screens using media queries */
@media (max-width: 860px) {
    .interior-painting {
        flex-direction: column-reverse;
        padding-top: 10%;
        margin-bottom: 10%;
    }

    .image-container {
        padding-left: 0;
        width: 100%;
        height: 50vh; /* Adjust the height as needed */
    }

    .text-container {
        width: 90%;
        height: auto;
        top: auto;
        left: 5%;
        padding: 5%;
    }
}
