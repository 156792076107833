/* styles.css or Loading.module.css */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white; /* #BABFD1; */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-spinner {
    border: 12px solid white; /* White outer ring */
    border-top: 12px solid #143B5D; /* Blue spinner */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

.loading-text {
    margin-top: 2%;
}
