figcaption {
    position: absolute;
    top: 2em;
    color: black;
    font-family: "Montesserat";
    font-size: 24px;
}

img {
    height: 100%;
}

.hero {
    text-align: center;
}

.waitlist-title {
    color: black;
    margin-right: 7%;
    margin-left: 7%;
}

.item {
    opacity: 0;
    transition: opacity 0.5s ease-in; /* Adjust duration and easing as needed */
  }
  
.fadeIn {
    opacity: 1;
  }  

.hero-section {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: black;
    overflow: hidden;
    /* margin-top: -5.2%; */
    /* padding-top: 5%; */
  }
  
  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-attachment: fixed;
  }
  
  .hero-text-top-left {
    position: absolute;
    right: 2%;
    top: 10%;
    color: white;
    width: 50%;
  }

  .hero-text-header {
    font-size: 5.5vh;
    font-family: Roboto;
  }

  .hero-top-left-subtext-1 {
    margin-top: 8%;
    font-size: 3vh;
    font-family: Roboto;
  }

  .hero-top-left-subtext-2 {
    font-size: 3vh;
    font-family: Roboto;
  }
  
  .hero-text-bottom-right {
    position: absolute;
    bottom: 25vh;
    right: 2%;
    color: white;
    width: 50%;
  }
  
  .bullet-points {
    list-style: none;
    padding: 0;
  }
  
  .bullet-point {
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 0.5s ease-in, transform 0.5s ease-in;
    color: white;
    font-family: Roboto;
    font-size: 3vh;
  }
  
  /* .bullet-point-0 {
    transition-delay: 0s;
  }
  
  .bullet-point-1 {
    transition-delay: 0.2s;
  }
  
  .bullet-point-2 {
    transition-delay: 0.4s;
  }
  
  .bullet-point-3 {
    transition-delay: 0.6s;
  } */
  
  .bullet-point.active {
    opacity: 1;
    transform: translateX(0);
  }

  /* Media Queries for Responsiveness */
@media only screen and (max-width: 1000px) {
  .hero-text-top-left {
    width: 50%;
  }

  .hero-text-bottom-right {
    width: 50%;
    padding-bottom: 5%;
  }

  .hero-top-left-subtext-1,
  .hero-top-left-subtext-2,
  .bullet-point {
    font-size: 2.5vh;
  }
}

@media only screen and (max-width: 768px) {
  .hero-text-top-left {
    top: 3%;
    width: 70%;
  }

  .hero-text-bottom-right {
    width: 70%;
    padding-bottom: 15%;
  }

  .hero-text-header {
    font-size: 4vh;
  }

  .hero-top-left-subtext-1,
  .hero-top-left-subtext-2,
  .bullet-point {
    font-size: 2.5vh;
  }
}

@media only screen and (max-width: 480px) {
  .hero-text-top-left {
    top: 2%;
    right: 1%;
    width: 90%;
  }

  .hero-text-bottom-right {
    bottom: 5%;
    right: 1%;
    width: 90%;
    padding-bottom: 60%;
  }

  .hero-text-header {
    font-size: 3vh;
  }

  .hero-top-left-subtext-1,
  .hero-top-left-subtext-2,
  .bullet-point {
    font-size: 2vh;
  }
}

