@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html, body, html * {
  font-family: 'Roboto', sans-serif;
}

.cta-box {
  padding-top: 5%;
  height:61vh;
  width:100%;
  background: linear-gradient(to bottom right, #BABFD1, #143B5D);
  text-align: center;
  /* margin-bottom: 5%; */
  /* background:linear-gradient(rgba(250,0,0,0.5),transparent);
  background-color: orange; */
}

.value {
    color: #143B5D;
    font-family: "Montserrat";
    margin-bottom: 5%;
}

.value-title {
    font-size: 1.45rem;
    font-weight: bold;
}

.value-content {
    font-size: 1.375rem;
}

.cta {
    margin-bottom: 2%;
}

.cta-subtext {
    color: white;
    font-family: "Roboto";
    font-size: 1.15rem;
}

.waitlist-title-2 {
    color: black;
    margin-right: 7%;
    margin-left: 7%;
}

.cta2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding: 5%;
    height: 100vh;
}

.cta2-header {
    font-size: 5.5vh;
    margin-top: 5%;
    color: black;
}

.cta2-text {
    font-size: 3.5vh;
    margin-top: 5%;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 5%;
    text-align: center;
    color: black;
}

.signup-button2 {
    margin-top: 5%;
    font-size: 3vh;
    padding: 1vw 3vh;
    border-radius: 5%;
    background-color: #143B5D;
    color: white;
    text-decoration: none;
    font-weight: bold;
    align-items: center;
}
