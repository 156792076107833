.design-container {
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
}

.design-container h2,
.design-container h4 {
    margin-bottom: 10px;
}

.design-container input[type="text"],
.design-container input[type="file"],
.design-container select,
.design-container button {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.design-container button {
    background-color: #143B5D;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
}

.design-container button:hover {
    background-color: #0a2533;
}

.design-container .products-list {
    margin-top: 20px;
}

.design-container table {
    border-collapse: collapse;
    width: 100%;
}

.design-container tr {
    border: 1px solid black;
}

.design-container td, .design-container th {
    border: 1px solid black;
    padding: 8px;
}

.design-container .rec-title {
    color: black;
    font-family: "Roboto";
    margin-bottom: 2%;
}

.design-container .buy-product {
    background-color: #143B5D;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}

/* Navbar styles */
.navbar {
    background-color: white !important;
    padding-right: 3%;
    padding-left: 1%;
}

.navbar.navbar-light {
    border-bottom: none !important;
}

.nav-link {
    color: #143B5D !important;
    font-family: "Roboto";
}

.navbar-nav {
    margin-left: auto;  /* This will right-align the nav items */
}

.loading-results {
    margin-top: 5%;
    text-align: center;
}

/* .state {
    border: solid 1px #eee;
    font-size: 14px;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Roboto';
} */

.plangptform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Spacing between child elements */
}

.loaderIcon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

