.categories-title {
    margin-top: 5%;
    color: black;
    margin-bottom: 5%;
    text-align: center;
    font-size: 26px;
}

.specialized-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.specialized-categories button {
    width: 300px;
    text-align: center;
}

.product-category-button {
    height: 14vh;
}

.next-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.next-button:hover {
    background-color: #0056b3;
}

.button-selected {
    background-color: #BABFD1
}
